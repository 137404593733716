/* ------------------------------------ *\
    CUSTOMER ACCOUNT
\* ------------------------------------ */

@import "customer-account/addresses";
@import "customer-account/announcement";
@import "customer-account/business-data-form";
@import "customer-account/business-information";
@import "customer-account/buyer-detail";
@import "customer-account/content";
@import "customer-account/header";
@import "customer-account/subheader";
@import "customer-account/menu";
@import "customer-account/newsletter";
@import "customer-account/offer";
@import "customer-account/order";
@import "customer-account/password";
@import "customer-account/states";
@import "customer-account/table";
@import "customer-account/user-info";
@import "customer-account/users";
@import "customer-account/widget";
@import "customer-account/shopping-list-usps";
@import "customer-account/payment-method";
@import "customer-account/no-orders-found";
@import "customer-account/no-orders-found-md";
@import "customer-account/no-orders-found-xl";
@import "customer-account/toggle-filter";
@import "customer-account/order-history";
@import "customer-account/order-history-lg";
@import "customer-account/order-history-search-bar";
@import "customer-account/order-history-search-bar-lg";
@import "customer-account/order-history-search-bar-xxl";
@import "customer-account/plus/faq";
@import "customer-account/plus/points-overview";
@import "customer-account/plus/points-overview-md";
@import "customer-account/plus/points-overview-xxl";
@import "customer-account/partials/company-size-nudge";
@import "customer-account/partials/company-size-nudge-md";

/**
 * remove after customer setting page is live
 */
.customer-account__widget--disabled--link {
    margin-left: $space * 4;
    position: relative;
    width: 100%;
}

@each $page in $customer-account-pages {
    .customer-account-#{$page} {
        .main-wrapper {
            background-color: $grey--light !important;
        }
    }

    @if $page != 'overview' {
        .customer-account-#{$page} {
            .footer {
                margin-top: $space * 6;
            }
        }
    }
}

@include media(lg) {
    @each $page in $customer-account-pages {
        .customer-account-#{$page} {
            .footer {
                margin-top: $space * 12;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:578";
//Deprecated: Please use static/assets/Yves/styles/v2/base/mixins/_collapsible.scss mixin
// and twig macro for implementing collapsible behaviour

/* ------------------------------------ *\
    COLLAPSIBLE HEADERS AND CONTENT
\* ------------------------------------ */

.product-configurator__header.collapsed {
    & > .fa::before { content: $fa-var-angle-down; } //.fa-angle-down
}

.product-configurator__content {
    padding-top: $space * 3;
    max-height: 100%;
    overflow: hidden;

    &.collapsed {
        padding-top: 0;
        max-height: 0;
    }
}

/* Cart */
.cart__header {
    position: relative;
    cursor: pointer;
    background-color: $grey--light;

    @include media(lg) {
        cursor: auto;
        background-color: #fff;
    }

    & > .fa {
        position: absolute;
        top: 8px;
        right: $space * 2;
    }

    &.collapsed > .fa::before { content: $fa-var-angle-down; }
}

.cart__content {
    max-height: 100%;

    &.collapsed {
        max-height: 0;
        overflow: hidden;
        @include media(lg) {
            max-height: 100%;
            overflow: visible;
        }
    }
}

;@import "sass-embedded-legacy-load-done:283";
.o-image-gallery {
    &__preview-image--single {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: ($space * 2) 0;
        height: $image-size-xl;
    }

    &__preview-image--single:hover &__nav-icon--left,
    &__preview-image--single:hover &__nav-icon--right {
        opacity: 1;
    }

    &__preview-image--picture {
        display: flex;
        justify-content: center;
    }

    &__nav-icon--left,
    &__nav-icon--right {
        opacity: 0;
        transition: opacity 0.2s;
        font-size: $font-size--xlarge;
        border: none;
        color: $grey--medium;
        background: transparent;
        top: calc(50% - 22.5px);
    }

    &__nav-icon--left {
        position: absolute;
        left: ($space * 5);
        z-index: 2;
    }

    &__nav-icon--right {
        position: absolute;
        right: ($space * 5);
        z-index: 2;
    }

    &__thumb-image {
        width: auto;
        height: auto;
    }

    &__image-wrapper {
        position: relative;
        max-width: 490px;
        margin: auto;
        overflow: hidden;
    }
}

;@import "sass-embedded-legacy-load-done:1316";
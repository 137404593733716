//TODO: Deprecated - Remove once all usages are gone
//Refactored Version: /v2/components/_product-recommendations.organism.scss

/* ------------------------------------ *\
    RECOMMENDATIONS
\* ------------------------------------ */
.recommendations.recommendations--show-add {
    .product-widget__image,
    .product-widget__data {
        width: 100%;
        padding-right: 0;
    }
}

.recommendations.collapsed {
    display: none;

    @include media(lg) {
        display: flex;
    }
}

.recommendations__placeholder {
    height: 460px;
    &:empty {
        align-items: center;
        display: flex;
        justify-content: center;
        &::after {
            animation: spin 0.5s infinite steps(9);
            color: $blue;
            content: $fa-var-cog;
            font-family: "FontAwesome";
            font-size: $font-size--large;
        }
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    0% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

;@import "sass-embedded-legacy-load-done:538";
.product-relations-v2 {
    &__show-all-accessories {
        margin: ($space * 4) 0;
        font-weight: $font-weight--bold;
        display: flex;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }

    .btn {
        height: auto;

        label {
            padding: $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1270";
@include media(lg) {
    .t-panel-v2 {
        margin-left: 0;
        margin-right: 0;

        &__header {
            &--stretched {
                h2 {
                    font-family: $font-family--main;
                    font-size: $font-size--xlarge-plus;
                    line-height: $line-height--xlarge;
                }
            }
        }

        &__body {
            padding-bottom: $space * 14;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1470";
.shipment-options-spacer {
    position: relative;

    &::after {
        content: "";
        height: 50px;
        width: 100%;
        background: $grey--lighter;
        position: absolute;
        right: -100%;
        display: block;
        top: 0;
        z-index: 13;
    }
}

.shipment-options {
    position: relative;

    &__row {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: ($space * 2) 0;

        &:first-child {
            padding-top: 0;
        }
    }

    &__option {
        flex: 1;
    }

    &__input {
        align-items: center;
        display: flex;
        position: relative;

        .radio-btn {
            top: 10px;
        }
    }

    &__delivery-time {
        margin-left: $space * 6;
        margin-right: $space * 2;
    }

    &__price {
        font-weight: $font-weight--bold;
        margin-left: auto;
        text-align: right;

        @include media(xl) {
            margin-left: $space * 6;
            text-align: left;
        }

        @include media(xxl) {
            margin-left: 0;
            text-align: right;
        }

        &__tax-hint {
            color: $grey--dark;
            display: block;
            font-size: $font-size--small;
            font-weight: $font-weight--base;
            line-height: 1;
        }
    }

    &__max-ordering-time {
        padding-left: $space * 6;
        position: relative;

        &::before {
            content: $contorion-icon--clock-solid;
            color: $green;
            font-family: $contorion-font-family;
            font-size: 20px;
            height: 15px;
            left: 0;
            position: absolute;
            width: 15px;
        }
    }

    &__label {
        display: flex;
        flex-wrap: wrap;
        font-weight: $font-weight--bold;
    }
}

.shipment-options-container {
    @include border($where: top);
    @include media(xl) {
        @include border($where: top, $width: 0);
        @include border($where: left);
        flex: calc(3 / 5);
    }
}

.shipment-items-container {
    @include media(xl) {
        flex: 1;
    }
}

.shipment-group {
    box-shadow: $shadow6;
    background-color: white;
    margin-bottom: $space * 4;

    &__express-delivery {
        color: $green;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
        position: relative;
        display: block;
        padding-left: $space * 3;
        margin-right: $space;

        &::before {
            content: $contorion-icon--express-delivery;
            font-family: $contorion-font-family;
            position: absolute;
            left: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:922";
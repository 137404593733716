/* ----------------------------------- *\
    BUTTONS

    .btn and .btn--default are loaded
    in the critical path
\* ------------------------------------ */

.btn {
    @include border($color: transparent);
    position: relative;
    display: inline-block;
    min-width: 40px;
    height: 40px;
    padding: 10px 12px 11px;
    text-align: center;
    color: #fff;
    background: none;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    letter-spacing: normal;
    border-radius: $border-radius;

    @include media(lg) {
        min-width: 32px;
        height: 32px;
        padding-top: 5px;
        padding-bottom: 6px;
    }

    &:hover { text-decoration: none; }

    &.btn--inverted {
        background-color: #fff;

        &:hover {

            &,
            & > .fa,
            & > [class^="icon--"] { color: #fff; }
        }
    }

    &.disabled,
    &[disabled] {
        color: $grey--dark;
        background-color: $grey--light;
        border-color: $grey;

        &.btn--inverted {
            color: $grey--dark;
            border-color: $grey;
            & > .fa { color: $grey--dark; }
        }

        &:hover,
        &.btn--inverted:hover { background-color: $grey--light; }
    }

    &.btn--icon-left > .fa,
    &.btn--icon-left > [class^="icon--"] { margin-right: $space; }

    &.btn--icon-right > .fa,
    &.btn--icon-right > [class^="icon--"] { margin-left: $space; }

    & > .fa { font-size: $font-size--regular-plus; }

    & > .fa.loading { font-size: $font-size--large; }

    & > .icon--wishlist-icon {
        font-size: $font-size--medium;
        position: relative;
        top: 2px;
    }

    &--scroll-up {
        position: fixed;
        bottom: $space * 4;
        right: $space * 4;
        height: 42px;
        opacity: 0.85;
        transition: opacity 0.3s $transition-ease;
        z-index: $z4;

        &.no-touch:hover {
            visibility: visible !important;
            opacity: 1;
        }

        &:hover {
            border: none;
        }
    }

    &--scroll-up-loyalty {
        display: none;
        left: $space * 4;

        @include media(xl) {
            display: unset;
        }
    }
}

.btn--default {
    background-color: $theme-color;
    &:hover { background-color: $theme-color; }
    &.btn--inverted {
        color: $theme-color;
        border-color: $theme-color;
    }
    &.btn--inverted:hover { background-color: $theme-color; }
    &.btn--inverted > .fa,
    &.btn--inverted > [class^="icon--"] { color: $theme-color; }
}

/* When the modifiers change padding, margin or height; increase the priority */
.btn.btn--small {
    min-width: 26px;
    height: 26px;
    padding: 2px 6px;
}

.btn.btn--buy {
    height: 48px;
    padding: $space * 2;
}

.btn--success {
    background-color: $green;
    &:hover { background-color: $green; }
    &.btn--inverted {
        color: $green;
        border-color: $green;
    }
    &.btn--inverted:hover { background-color: $green; }
    &.btn--inverted > .fa,
    &.btn--inverted > [class^="icon--"] { color: $green; }
}

.btn--warning {
    background-color: $yellow;
    &:hover { background-color: $yellow; }
    &.btn--inverted {
        color: $yellow;
        border-color: $yellow;
    }
    &.btn--inverted:hover { background-color: $yellow; }
    &.btn--inverted > .fa,
    &.btn--inverted > [class^="icon--"] { color: $yellow; }
}

.btn--error {
    background-color: $red;
    &:hover { background-color: $red; }
    &.btn--inverted {
        color: $red;
        border-color: $red;
    }
    &.btn--inverted:hover { background-color: $red; }
    &.btn--inverted > .fa,
    &.btn--inverted > [class^="icon--"] { color: $red; }
}

.btn--grey {
    color: $base-text-color;
    background-color: $grey--light;
    &:hover { background-color: $grey; }

    &,
    &.disabled,
    &[disabled] { border-color: $grey; }
}

.btn--flat {
    color: $base-text-color;
    background-color: #fff;
    border-color: $border-default-color;
    &:hover { background-color: $grey--light; }
}

.btn--icon {
    display: flex;
    padding: 0;
    color: $theme-color;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 0;

    &[disabled] {
        background-color: #fff;
    }

    &:hover {
        background-color: $grey--light;
    }
}

.btn--nav {
    color: $base-text-color;
    background-color: #fff;

    &.disabled,
    &[disabled] {
        color: $grey;
        background-color: #fff;
        border: 0;
    }

    &::before {
        font-family: $font-family--font-awesome;
        font-size: $font-size--medium;
    }
}

.btn--nav--left {
    box-shadow: $box-shadow-button--nav-left;
    &:hover { box-shadow: $box-shadow-button--nav-left__hover; }

    &.disabled,
    &[disabled] { box-shadow: $box-shadow-button--nav-left--inactive; }

    &::before { content: $fa-var-chevron-left; }
}

.btn--nav--right {
    box-shadow: $box-shadow-button--nav-right;
    &:hover { box-shadow: $box-shadow-button--nav-right__hover; }

    &.disabled,
    &[disabled] { box-shadow: $box-shadow-button--nav-right--inactive; }

    &::before { content: $fa-var-chevron-right; }
}

.btn--buy {

    .btn--buy__icon {
        display: inline-block;
        font-size: 22px;
        width: $space * 6;
        margin-right: $space;
    }

    .btn--buy__icon--loading { display: none; }

    &.inProgress,
    .inProgress & {
        .btn--buy__icon { display: none; }
        .btn--buy__icon--loading { display: inline-block; }
    }
}

.btn--message {
    .btn--message__message {
        display: inline-block;
        width: 126px; //This is the width of the longest message
    }

    .btn--message__message--hover { display: none; }

    &:hover {
        cursor: not-allowed;
        background-color: $yellow !important;
        border-color: $yellow !important;

        .btn--message__message { display: none; }
        .btn--message__message--hover { display: inline-block; }
    }
}

/**
 ** Firefox does not allow <button> to be a flex container,
 ** a <span> wrapper has been added to achieve the same result
 **/
.btn--buy__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn--close {
    color: $grey;
    cursor: pointer;
    font-size: 20px;
    height: 10px;
    width: 10px;

    &:hover {
        color: $grey--dark;
    }

    &::before {
        content: $fa-var-times;
        font-family: "FontAwesome";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

;@import "sass-embedded-legacy-load-done:212";
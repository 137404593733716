/* THIS FILE IS BEING AUTO-GENERATED */

// Variables
// --------------------------

$fa-font-path:        "../fonts" !default;
$fa-font-size-base:   14px !default;
$fa-line-height-base: 1 !default;
//$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts" !default; // for referencing Bootstrap CDN font files directly
$fa-css-prefix:       fa !default;
$fa-version:          "4.6.3" !default;
$fa-border-color:     #eee !default;
$fa-inverse:          #fff !default;
$fa-li-width:         calc(30em / 14) !default;


$fa-var-angle-down: "\EA01";

$fa-var-angle-left: "\EA02";

$fa-var-angle-right: "\EA03";

$fa-var-angle-up: "\EA04";

$fa-var-arrow-down: "\EA05";

$fa-var-arrow-right: "\EA06";

$fa-var-ban: "\EA07";

$fa-var-bars: "\EA08";

$fa-var-bell-slash: "\EA09";

$fa-var-bell: "\EA0A";

$fa-var-book: "\EA0B";

$fa-var-bullhorn: "\EA0C";

$fa-var-calculator: "\EA0D";

$fa-var-calendar-check-o: "\EA0E";

$fa-var-caret-down: "\EA0F";

$fa-var-caret-right: "\EA10";

$fa-var-check-square-o: "\EA11";

$fa-var-check: "\EA12";

$fa-var-chevron-down: "\EA13";

$fa-var-chevron-left: "\EA14";

$fa-var-chevron-right: "\EA15";

$fa-var-chevron-up: "\EA16";

$fa-var-circle: "\EA17";

$fa-var-clock-o: "\EA18";

$fa-var-close: "\EA19";

$fa-var-code: "\EA1A";

$fa-var-cog: "\EA1B";

$fa-var-cogs: "\EA1C";

$fa-var-comments-o: "\EA1D";

$fa-var-comments: "\EA1E";

$fa-var-credit-card-alt: "\EA1F";

$fa-var-cube: "\EA20";

$fa-var-cubes: "\EA21";

$fa-var-desktop: "\EA22";

$fa-var-download: "\EA23";

$fa-var-envelope: "\EA24";

$fa-var-euro: "\EA25";

$fa-var-exchange: "\EA26";

$fa-var-exclamation-triangle: "\EA27";

$fa-var-facebook: "\EA28";

$fa-var-fax: "\EA29";

$fa-var-file-text: "\EA2A";

$fa-var-flag: "\EA2B";

$fa-var-floppy-o: "\EA2C";

$fa-var-folder-open: "\EA2D";

$fa-var-google-plus: "\EA2E";

$fa-var-history: "\EA2F";

$fa-var-info-circle: "\EA30";

$fa-var-info: "\EA31";

$fa-var-instagram: "\EA32";

$fa-var-laptop: "\EA33";

$fa-var-linkedin-square: "\EA34";

$fa-var-linkedin: "\EA35";

$fa-var-list-alt: "\EA36";

$fa-var-list-ol: "\EA37";

$fa-var-list: "\EA38";

$fa-var-lock: "\EA39";

$fa-var-map-marker: "\EA3A";

$fa-var-map-signs: "\EA3B";

$fa-var-minus: "\EA3C";

$fa-var-mobile-phone: "\EA3D";

$fa-var-mobile: "\EA3E";

$fa-var-paint-brush: "\EA3F";

$fa-var-pencil: "\EA40";

$fa-var-percent: "\EA41";

$fa-var-phone: "\EA42";

$fa-var-pie-chart: "\EA43";

$fa-var-play-circle-o: "\EA44";

$fa-var-plus: "\EA45";

$fa-var-print: "\EA46";

$fa-var-question-circle: "\EA47";

$fa-var-quote-left: "\EA48";

$fa-var-quote-right: "\EA49";

$fa-var-refresh: "\EA4A";

$fa-var-rocket: "\EA4B";

$fa-var-search-plus: "\EA4C";

$fa-var-search: "\EA4D";

$fa-var-shopping-basket: "\EA4E";

$fa-var-shopping-cart: "\EA4F";

$fa-var-sign-out: "\EA50";

$fa-var-spinner: "\EA51";

$fa-var-star-half-o: "\EA52";

$fa-var-star-o: "\EA53";

$fa-var-star: "\EA54";

$fa-var-table: "\EA55";

$fa-var-tag: "\EA56";

$fa-var-tags: "\EA57";

$fa-var-th-list: "\EA58";

$fa-var-th: "\EA59";

$fa-var-times-circle: "\EA5A";

$fa-var-times: "\EA5B";

$fa-var-trash-o: "\EA5C";

$fa-var-truck: "\EA5D";

$fa-var-usd: "\EA5E";

$fa-var-user: "\EA5F";

$fa-var-warning: "\EA60";

$fa-var-whatsapp: "\EA61";

$fa-var-wrench: "\EA62";

$fa-var-youtube-play: "\EA63";


/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */


.#{$fa-css-prefix}-angle-down::before { content: $fa-var-angle-down; }

.#{$fa-css-prefix}-angle-left::before { content: $fa-var-angle-left; }

.#{$fa-css-prefix}-angle-right::before { content: $fa-var-angle-right; }

.#{$fa-css-prefix}-angle-up::before { content: $fa-var-angle-up; }

.#{$fa-css-prefix}-arrow-down::before { content: $fa-var-arrow-down; }

.#{$fa-css-prefix}-arrow-right::before { content: $fa-var-arrow-right; }

.#{$fa-css-prefix}-ban::before { content: $fa-var-ban; }

.#{$fa-css-prefix}-bars::before { content: $fa-var-bars; }

.#{$fa-css-prefix}-bell-slash::before { content: $fa-var-bell-slash; }

.#{$fa-css-prefix}-bell::before { content: $fa-var-bell; }

.#{$fa-css-prefix}-book::before { content: $fa-var-book; }

.#{$fa-css-prefix}-bullhorn::before { content: $fa-var-bullhorn; }

.#{$fa-css-prefix}-calculator::before { content: $fa-var-calculator; }

.#{$fa-css-prefix}-calendar-check-o::before { content: $fa-var-calendar-check-o; }

.#{$fa-css-prefix}-caret-down::before { content: $fa-var-caret-down; }

.#{$fa-css-prefix}-caret-right::before { content: $fa-var-caret-right; }

.#{$fa-css-prefix}-check-square-o::before { content: $fa-var-check-square-o; }

.#{$fa-css-prefix}-check::before { content: $fa-var-check; }

.#{$fa-css-prefix}-chevron-down::before { content: $fa-var-chevron-down; }

.#{$fa-css-prefix}-chevron-left::before { content: $fa-var-chevron-left; }

.#{$fa-css-prefix}-chevron-right::before { content: $fa-var-chevron-right; }

.#{$fa-css-prefix}-chevron-up::before { content: $fa-var-chevron-up; }

.#{$fa-css-prefix}-circle::before { content: $fa-var-circle; }

.#{$fa-css-prefix}-clock-o::before { content: $fa-var-clock-o; }

.#{$fa-css-prefix}-close::before { content: $fa-var-close; }

.#{$fa-css-prefix}-code::before { content: $fa-var-code; }

.#{$fa-css-prefix}-cog::before { content: $fa-var-cog; }

.#{$fa-css-prefix}-cogs::before { content: $fa-var-cogs; }

.#{$fa-css-prefix}-comments-o::before { content: $fa-var-comments-o; }

.#{$fa-css-prefix}-comments::before { content: $fa-var-comments; }

.#{$fa-css-prefix}-credit-card-alt::before { content: $fa-var-credit-card-alt; }

.#{$fa-css-prefix}-cube::before { content: $fa-var-cube; }

.#{$fa-css-prefix}-cubes::before { content: $fa-var-cubes; }

.#{$fa-css-prefix}-desktop::before { content: $fa-var-desktop; }

.#{$fa-css-prefix}-download::before { content: $fa-var-download; }

.#{$fa-css-prefix}-envelope::before { content: $fa-var-envelope; }

.#{$fa-css-prefix}-euro::before { content: $fa-var-euro; }

.#{$fa-css-prefix}-exchange::before { content: $fa-var-exchange; }

.#{$fa-css-prefix}-exclamation-triangle::before { content: $fa-var-exclamation-triangle; }

.#{$fa-css-prefix}-facebook::before { content: $fa-var-facebook; }

.#{$fa-css-prefix}-fax::before { content: $fa-var-fax; }

.#{$fa-css-prefix}-file-text::before { content: $fa-var-file-text; }

.#{$fa-css-prefix}-flag::before { content: $fa-var-flag; }

.#{$fa-css-prefix}-floppy-o::before { content: $fa-var-floppy-o; }

.#{$fa-css-prefix}-folder-open::before { content: $fa-var-folder-open; }

.#{$fa-css-prefix}-google-plus::before { content: $fa-var-google-plus; }

.#{$fa-css-prefix}-history::before { content: $fa-var-history; }

.#{$fa-css-prefix}-info-circle::before { content: $fa-var-info-circle; }

.#{$fa-css-prefix}-info::before { content: $fa-var-info; }

.#{$fa-css-prefix}-instagram::before { content: $fa-var-instagram; }

.#{$fa-css-prefix}-laptop::before { content: $fa-var-laptop; }

.#{$fa-css-prefix}-linkedin-square::before { content: $fa-var-linkedin-square; }

.#{$fa-css-prefix}-linkedin::before { content: $fa-var-linkedin; }

.#{$fa-css-prefix}-list-alt::before { content: $fa-var-list-alt; }

.#{$fa-css-prefix}-list-ol::before { content: $fa-var-list-ol; }

.#{$fa-css-prefix}-list::before { content: $fa-var-list; }

.#{$fa-css-prefix}-lock::before { content: $fa-var-lock; }

.#{$fa-css-prefix}-map-marker::before { content: $fa-var-map-marker; }

.#{$fa-css-prefix}-map-signs::before { content: $fa-var-map-signs; }

.#{$fa-css-prefix}-minus::before { content: $fa-var-minus; }

.#{$fa-css-prefix}-mobile-phone::before { content: $fa-var-mobile-phone; }

.#{$fa-css-prefix}-mobile::before { content: $fa-var-mobile; }

.#{$fa-css-prefix}-paint-brush::before { content: $fa-var-paint-brush; }

.#{$fa-css-prefix}-pencil::before { content: $fa-var-pencil; }

.#{$fa-css-prefix}-percent::before { content: $fa-var-percent; }

.#{$fa-css-prefix}-phone::before { content: $fa-var-phone; }

.#{$fa-css-prefix}-pie-chart::before { content: $fa-var-pie-chart; }

.#{$fa-css-prefix}-play-circle-o::before { content: $fa-var-play-circle-o; }

.#{$fa-css-prefix}-plus::before { content: $fa-var-plus; }

.#{$fa-css-prefix}-print::before { content: $fa-var-print; }

.#{$fa-css-prefix}-question-circle::before { content: $fa-var-question-circle; }

.#{$fa-css-prefix}-quote-left::before { content: $fa-var-quote-left; }

.#{$fa-css-prefix}-quote-right::before { content: $fa-var-quote-right; }

.#{$fa-css-prefix}-refresh::before { content: $fa-var-refresh; }

.#{$fa-css-prefix}-rocket::before { content: $fa-var-rocket; }

.#{$fa-css-prefix}-search-plus::before { content: $fa-var-search-plus; }

.#{$fa-css-prefix}-search::before { content: $fa-var-search; }

.#{$fa-css-prefix}-shopping-basket::before { content: $fa-var-shopping-basket; }

.#{$fa-css-prefix}-shopping-cart::before { content: $fa-var-shopping-cart; }

.#{$fa-css-prefix}-sign-out::before { content: $fa-var-sign-out; }

.#{$fa-css-prefix}-spinner::before { content: $fa-var-spinner; }

.#{$fa-css-prefix}-star-half-o::before { content: $fa-var-star-half-o; }

.#{$fa-css-prefix}-star-o::before { content: $fa-var-star-o; }

.#{$fa-css-prefix}-star::before { content: $fa-var-star; }

.#{$fa-css-prefix}-table::before { content: $fa-var-table; }

.#{$fa-css-prefix}-tag::before { content: $fa-var-tag; }

.#{$fa-css-prefix}-tags::before { content: $fa-var-tags; }

.#{$fa-css-prefix}-th-list::before { content: $fa-var-th-list; }

.#{$fa-css-prefix}-th::before { content: $fa-var-th; }

.#{$fa-css-prefix}-times-circle::before { content: $fa-var-times-circle; }

.#{$fa-css-prefix}-times::before { content: $fa-var-times; }

.#{$fa-css-prefix}-trash-o::before { content: $fa-var-trash-o; }

.#{$fa-css-prefix}-truck::before { content: $fa-var-truck; }

.#{$fa-css-prefix}-usd::before { content: $fa-var-usd; }

.#{$fa-css-prefix}-user::before { content: $fa-var-user; }

.#{$fa-css-prefix}-warning::before { content: $fa-var-warning; }

.#{$fa-css-prefix}-whatsapp::before { content: $fa-var-whatsapp; }

.#{$fa-css-prefix}-wrench::before { content: $fa-var-wrench; }

.#{$fa-css-prefix}-youtube-play::before { content: $fa-var-youtube-play; }


;@import "sass-embedded-legacy-load-done:82";
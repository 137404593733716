@include media(md) {
    .o-product-widget-small {
        &--tile-view {
            grid-template-columns: unset;
        }

        &__image-container--tile-view {
            grid-row: unset;
            grid-column: unset;
            flex-direction: unset;
            justify-content: center;
        }

        &__image--tile-view {
            width: $image-size-xm;
            height: $image-size-xm;
            margin: 0 2 * $space;
        }

        &__badges--tile-view {
            flex-direction: row;
            position: absolute;
            top: 0;
            justify-content: space-between;
            align-content: flex-end;
            gap: unset;
        }

        &__product-details--tile-view {
            grid-row: unset;
            grid-column: unset;
        }

        &__product-name--tile-view {
            display: -webkit-box;
            overflow: hidden;
            overflow-wrap: anywhere;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2 * $line-height--regular;
        }

        &__rating--hidden {
            display: block;
        }

        &__delivery--tile-view {
            grid-row: unset;
            grid-column: unset;
            grid-row-end: span 4;
        }

        &__price-tag--tile-view {
            grid-row: unset;
            grid-column: unset;
            grid-row-end: span 7;
        }

        &__retail-price--tile-view {
            height: 18px;
        }

        &__variants--tile-view {
            grid-row: unset;
            grid-column: unset;
        }

        &__sponsored {
            &.-tile-view {
                margin-bottom: -$space * 2;
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:1246";
.m-rating-stars-v2 {

    height: 14px;

    &-large {
        height: 23px;
        display: flex;
        align-items: center;
        max-width: max-content;

        &--label {
            color: $grey--dark;
            margin-left: 10px;
        }
    }

    &--label {
        color: $grey--dark;
    }

    &__star-icon {
        color: $yellow;
        font-size: $font-size--regular-plus;
        margin-right: 1px;

        &-large {
            color: $yellow;
            font-size: 23px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1214";
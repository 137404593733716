$first-color: #73b12c;
$last-color: #d22415;
$colors-list: $first-color #8ab42c #acb92f #c7bd30 #dfc231 #eabf2e #e8aa2c #e38e27 #de691f #d8481b $last-color;

@each $score-color in $colors-list {
    $i: index($colors-list, $score-color);
    .zl-score:nth-child(#{$i}) {
        background-color: $score-color;
    }
}

.zl-score {
    border-radius: 3px !important;
    color: #fff !important;
}

.zl-container {
    margin-bottom: $space * 3;
}

.zl-heading {
    font-family: $font-family--main !important;
    font-weight: $font-weight--bold !important;
    line-height: $line-height--large !important;
    font-size: $font-size--large !important;
}

.zl-extrem-left {
    color: $last-color;
}

.zl-extrem-right {
    color: $first-color;
}

.zl-answer-form {
    flex-wrap: wrap;
}

.zl-answer-message {
    margin-bottom: 30px !important;
}

.zl-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
}

.zl-input-container {
    padding-bottom: 30px !important;

    @include media(lg) {
        padding-bottom: 45px !important;
    }
}

.zl-textarea {
    max-width: 578px;
    min-height: 76px;
    margin-left: auto;
    margin-right: auto;
}

.zl-btn {
    display: flex;
}

.zl-footer {
    display: none;
}

.zl-thank-you-message {
    margin-bottom: 0 !important;
}

.zl-thank-you-page {
    .zl-btn-nav {
        display: none;
    }
}
;@import "sass-embedded-legacy-load-done:938";
@include media(lg) {
    .o-category-tree {
        &__header {
            margin-bottom: $space * 14;
        }

        &__block {
            padding-left: $space * 6;
            border: 0;

            &.o-category-tree__block--not-collapsible {
                .o-category-tree__block-item {
                    margin-bottom: $space * 4;
                    flex-grow: 1;
                }
            }

            &-item {
                margin-bottom: $space * 4;
                margin-left: 0;
            }
        }

        &__block:nth-child(even) {
            padding-left: $space * 4;
        }

        &__block:nth-child(odd) {
            padding-right: $space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1351";
@include media(lg) {
    .o-product-widget-small {
        .boss-26638-v1 {
            @include line-clamp(3);
            height: 3 * $line-height--regular;
        }

        .boss-26638-v2 {
            @include line-clamp(4);
            height: 4 * $line-height--regular;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1254";
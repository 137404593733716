//Renames static/assets/Yves/styles/v2/components/_expandable.scss

/* ------------------------------------ *\
    EXPANDABLE CONTENT GRADIENT BOTTOM
\* ------------------------------------ */

.t-expandable {
    &__content {
        overflow: hidden;
    }

    &__button {
        background-color: #fff;
        bottom: 0;
        display: none;
        height: $space * 4;
        left: 0;
        line-height: $space * 4;
        margin-top: $space;
        position: absolute;
        width: 100%;
        z-index: $z1;
    }

    &__button-text {
        color: $theme-color;
    }

    &--is-expandable {
        margin-bottom: $space * 5;

        padding-bottom: $space * 4;
        position: relative;
        &::after {
            background: rgba(#fff, 0.5);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(#fff, 0)), color-stop(100%, #fff));
            background: -webkit-linear-gradient(top, rgba(#fff, 0) 0%, #fff 100%);
            background: linear-gradient(to bottom, rgba(#fff, 0) 0%, #fff 100%);
            bottom: 20px;
            content: "";
            display: block;
            height: 100px;
            left: 0;
            position: absolute;
            right: 0;
        }

        .t-expandable__button {
            display: block;
        }
    }

    &--is-expanded {
        &::after {
            display: none;
        }

        .t-expandable__button {
            background-color: transparent;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1482";
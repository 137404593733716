@include media(lg) {
    .o-login-redirection-card {
        &-login {
            flex-direction: row;
            justify-content: space-between;
        }

        &__redirect-login {
            width: unset;

            &-button {
                width: 175px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1448";
//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT WIDGET - XL
\* ------------------------------------ */
@include media(xl) {
    .o-product-widget {
        &__buy-block--list-view {
            align-items: center;
            flex-direction: row;
            padding-right: 0;
            width: percentage(calc(1 / 2));
        }

        &__prices--list-view {
            width: 140px;
            align-items: flex-start;
            padding: 0 $space * 2 0 $space * 3;
            text-align: initial;
        }

        &__info--list-view {
            width: percentage(calc(1 / 2));
        }

    }
}

;@import "sass-embedded-legacy-load-done:1256";
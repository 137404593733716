@include media(lg) {
    .o-product-substitute {
        @include border('top');
        @include border('left');
        @include border('right');

        margin-top: $space * 3;
    }
}

;@import "sass-embedded-legacy-load-done:1308";
/* ------------------------------------ *\
    BOX SHADOWS
\* ------------------------------------ */

._sh1 { box-shadow: $shadow1; }

._sh1--hover:hover {
    transition: box-shadow 0.3s $transition-ease;
    box-shadow: $shadow2;
}

._sh2 { box-shadow: $shadow2; }

._sh2--hover:hover {
    transition: box-shadow 0.3s $transition-ease;
    box-shadow: $shadow3;
}

._sh3 { box-shadow: $shadow3; }

._sh3--hover:hover {
    transition: box-shadow 0.3s $transition-ease;
    box-shadow: $shadow4;
}

._sh4 { box-shadow: $shadow4; }

._sh4--hover:hover {
    transition: box-shadow 0.3s $transition-ease;
    box-shadow: $shadow5;
}

._sh6 { box-shadow: $shadow6; }

._sh7 { box-shadow: $shadow7; }

._sh8 { box-shadow: $shadow8; }

;@import "sass-embedded-legacy-load-done:234";
.newsletter-abo {
    &__email {
        @include border(top, $color: $grey--light);
        margin-top: $space * 2;
        padding-top: $space * 2;
    }

    .panel {
        &__body {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            text-align: center;

            .button {
                margin-top: $space * 2;
            }
        }
    }
}

@include media(md) {
    .newsletter-abo {
        .panel {
            &__footer {
                flex-wrap: nowrap;

                .button {
                    margin-top: 0;
                }
            }
        }
    }
}

@include media(xl) {
    .newsletter-abo {
        &__email {
            border: 0;
            margin-left: $space * 3;
            margin-top: 0;
            padding-top: 0;
            width: auto;
            min-width: 200px;
        }

        .panel {
            &__body {
                flex-direction: row;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:659";
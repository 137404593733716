//Refactors static/assets/Yves/styles/v2/components/_panel.scss

.t-panel {
    background-color: #fff;
    border-radius: $border-radius;
    position: relative;

    &--bordered {
        box-shadow: $shadow6;
    }

    &--border-top {
        @include border(top);
    }

    &--stretched {
        //This is needed to outgrow page container on smaller devices.
        margin-left: -($space * 2);
        margin-right: -($space * 2);
    }

    &__header,
    &__body,
    &__footer {
        position: relative;
    }

    &__header {
        &--stretched {
            padding-left: $space * 5;
            padding-right: $space;
            @include border($where: top);
            border-bottom: none;
        }

        &--title {
            font-size: $font-size--large;
            line-height: $line-height--large;
            font-family: $font-family--main;
        }
    }

    &__body {
        &--border-top {
            @include border($where: 'top');
        }

        padding: $space * 3;

        &--small-padding {
            padding: $space * 2;
        }

        &--unpadded {
            padding: 0;
        }
    }

    &__footer {
        background-color: $grey--lighter;
        border-radius: $border-radius;
        padding: $space * 3;
    }

    &__horizontal-break {
        border-color: $grey--light;
        margin: ($space * 2) 0 ($space * 3);
        width: auto;
    }

    &__content--in-modal {
        overflow-y: auto;
        h1,
        h2,
        h3 {
            font-weight: $font-weight--bold;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            margin-bottom: 1em;
        }

        backface-visibility: hidden; //prevents text blurriness in webkit
    }

    &__content--video {
        height: 0;
        overflow: hidden;
        padding-bottom: 50%;
        position: relative;
        width: 100%;

        iframe {
            height: 100% !important;
            left: 0;
            position: absolute;
            top: 0;
            width: 100% !important;
            z-index: $z1;
        }
    }

    &--small {
        max-width: 400px;
    }
}

;@import "sass-embedded-legacy-load-done:1464";
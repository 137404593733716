@include media(lg) {
    .m-full-size-banner {
        height: 264px;
        padding: ($space * 14) 0;

        &--light {
            background-image: url(/images/banner-fallbacks/concrete-tablet.png);
        }

        &__headline {
            white-space: nowrap;
        }

        &__button {
            padding-top: ($space * 8);
        }

        &__btn {
            min-width: 251px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1065";
/* ------------------------------------ *\
    TEAMS CMS PAGE
\* ------------------------------------ */

.team--small__icon {
    position: absolute;
    bottom: $space * 4;
    left: $space * 4;
}

.team__pic { transition: background-image 0.5s ease-in-out 0.15s; }

;@import "sass-embedded-legacy-load-done:756";
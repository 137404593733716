.o-product-highlights {
    margin-top: $space * 3;
    padding-left: $space;
    display: none;

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

;@import "sass-embedded-legacy-load-done:1297";
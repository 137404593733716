/* ------------------------------------ *\
    PDP
\* ------------------------------------ */

.pdp__banner {
    margin: ($space * 3) 0 ($space * 6) 0;

    a {
        line-height: 0;
    }
}

.pdp__main-img,
.pdp__main-info {
    position: relative;
    width: 100%;
}

.pdp__main-img {
    &--picture {
        @include media(md) {
            height: $image-size-xm;
        }
    }

    &--slider {
        height: $image-size-lg;
    }
}

.pdp__main-info {
    &__title {
        margin-bottom: $space * 2;
    }

    .product-specs {
        @include border('bottom');
    }
}

.pdp__main-img__preview {
    cursor: pointer;
    position: relative;

    img {
        display: block;
        max-height: 320px;
        max-width: 320px;
        margin: 0 auto;
    }

    & > .badge {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.pdp__img__thumb {

    & > .circle-dot {
        width: 13px;
        height: 13px;
        display: block;
        border-radius: 50%;
        background: $grey;
    }

    &.active {
        & > .circle-dot {
            background: $grey--darker;
        }
    }
}

.pdp__main-img-video-playbutton {
    //background: url(../../../images/playbutton.png) no-repeat;
    height: 42px;
    width: 145px;
    margin: 0 auto;
    background-size: cover;
}

.pdp__buy-box {
    position: relative;

    .quantity-field__container {
        position: absolute;
        top: 0;
        right: 0;
        .tooltip { min-width: 100%; }
    }

    .btn--stapler:hover {
        color: #fff;
        border-color: $yellow;
        background-color: $yellow;

        & > .message { display: none; }

        & > .message--hover {
            display: inline-block !important;
            visibility: visible;
        }
    }

    .loading &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
    }
}

.pdp__rating-container {
    max-height: 280px;
    overflow: auto;
}

.pdp-collapsible-block.collapsed {
    display: none;
    @include media(lg) { display: block; }
}

.pdp__coupon-info {
    margin: -$space (-$space * 3);
    padding: 0 ($space * 3);
    @include border($where: 'bottom', $width: 1px, $color: $grey);

    &__icon .fa {
        color: $blue;
        font-size: 20px;
    }

    &__voucher {
        font-weight: bold;
        color: $yellow;
    }
}

.pdp__substitute {
    box-shadow: $panel-shadow;
    margin-top: $space * 3;

    &-image {
        height: 80px;
        width: 80px;
    }

    &-link {
        color: $grey--darker;
        font-weight: $font-weight--bold;

        &:hover {
            text-decoration: none;
        }
    }

    &-title {
        max-height: 36px;
        overflow: hidden;

        &:hover {
            text-decoration: underline;
        }
    }
}

.pdp__sales-info__image {
    border-radius: 50%;
    flex: none;
    height: 40px;
    margin-right: $space * 3;
    width: 40px;
}

.loading {
    @include media(lg) {
        .pdp-loadable__placeholder {
            display: flex;
        }

        .pdp-product-description__placeholder {
            min-height: 235px;
        }

        .pdp_technical-data__placeholder {
            min-height: 220px;
        }

        .pdp-related-products__placeholder {
            min-height: 478px;
        }

        .product-comparison__placeholder {
            min-height: 628px;
        }
    }
}

.pdp-loadable__placeholder {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100px;
}

.pdp__sticky-add-to-cart {
    background: #fff;
    bottom: -90px;
    box-shadow: $shadow-top-only;
    max-height: 90px;
    padding: $space * 3;
    position: fixed;
    transition: bottom 0.2s;
    width: 100%;

    &-placeholder {
        background: $grey--darker;
        display: none;
        height: 85px;

        &--stuck {
            display: block;
        }
    }

    &--stuck {
        bottom: 0;
    }

    &-button {
        width: 100px;
    }

    &-image {
        max-height: 48px;
        max-width: 48px;
    }

    &-price {
        line-height: $line-height--large-plus;
    }
}

;@import "sass-embedded-legacy-load-done:889";
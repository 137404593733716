.t-product-highlight {
    margin: 0 auto;
    padding-top: ($space * 8);
    padding-bottom: ($space * 8);

    &__wrapper {
        background-color: $grey--light;
        margin-bottom: $space * 8;

        @include grow-outside-container;
    }

    &__header {
        margin-bottom: $space * 6;
        font-size: $font-size--xlarge;
        line-height: 43px;
    }

    &__main-content {
        margin-bottom: $space * 4;
    }

    &__sub-contents {
        display: block;
    }

    &__sub-content:first-child {
        margin-bottom: $space * 4;
    }

    &__sub-content {
        height: 100%;
        flex: 1;
    }
}

.product-highlights-example {
    background-color: $blue;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

;@import "sass-embedded-legacy-load-done:1364";
.dy-recommendation-widget {
    &__product-wrapper {
        width: 230px;
        min-width: 230px;
    }

    &__header {
        align-items: center;

        &.-border-top {
            @include border(top, $color: $grey);
        }

        &.-border-bottom {
            @include border(bottom, $color: $grey);
        }

        h2 {
            font-family: $font-family--main;
            font-size: $font-size--large;
            line-height: $line-height--xlarge;
        }
    }
}

;@import "sass-embedded-legacy-load-done:940";